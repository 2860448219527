var triggerScreen = "id-e4a2dbc8";
const heyflowLocalStorageKeyName = "heyflow-creation-sasu-wf-preserved";
const baseAPIUri = "https://clear-api.legalplace.fr/api/v1";

const EMAIL_LIST_TO_FILTER = [
    '@propulsebyca.fr',
    '@qonto.fr',
    '@shine.fr',
    '@pnlfin.tech',
    '@finom.co',
    '@blank.app',
    'test+filter@legalplace.fr',
];

function checkEmail(value) {
    let result = false;
    for (let i = 0; i < EMAIL_LIST_TO_FILTER.length; i++) {
        let emailToFilter = EMAIL_LIST_TO_FILTER[i];
        if (value.includes(emailToFilter)) {
            result = true;
            break;
        }
    }
    return result;
}

const CREA_OFFERTE_ACTIVITY = [
    'Consultants et freelance',
    'Informatique & web',
    'Services aux entreprises'
]

function checkActivity(value) {
    let result = false;
    for (let i = 0; i < CREA_OFFERTE_ACTIVITY.length; i++) {
        let freeCrea = CREA_OFFERTE_ACTIVITY[i];
        if (value.includes(freeCrea)) {
            result = true;
            break;
        }
    }
    return result;
}

const basicPostHeaders = {
    method: "POST",
    credentials: "include",
    headers: {
        "Content-Type": "application/json",
        "lp-referrer": window.document.referrer,
        "lp-origin": window.location.href,
    },
}

const getCookies = () => {
    return document.cookie.split('; ').reduce((prev, current) => {
        const [name, ...value] = current.split('=');
        prev[name] = value.join('=');
        return prev;
    }, {});
}

function setCookie(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
}

function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = document.cookie; //decodeURIComponent removed from function
    const cArr = cDecoded .split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}

const delay = (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
}

const getInstanceToken = (uniqid) => {
    let cookies = getCookies()
    return cookies[`instanceToken_${uniqid}`]
}

const getHeyflowSaveValueFromLocalStorage = (inputId) => {
    if (!localStorage || !localStorage[heyflowLocalStorageKeyName]) {
        return null;
    }
    const heyflowSavedValues = JSON.parse(
        localStorage[heyflowLocalStorageKeyName]
    );
    if (
        !heyflowSavedValues[inputId] ||
        !heyflowSavedValues[inputId]["values"] ||
        !heyflowSavedValues[inputId]["values"][0] ||
        !heyflowSavedValues[inputId]["values"][0]["answer"]
    ) {
        return null;
    }
    return heyflowSavedValues[inputId].values[0].answer;
};

function deleteHeyflowLocalStorage() {
    localStorage.removeItem(heyflowLocalStorageKeyName);
};

const getUrlParam = (paramId) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(paramId)
}

function checkIfInstanceIsPaidFunction(uniqid, token) {
    const checkIfInstanceIsPaid = {
        method: 'GET'
        }
    fetch(`${baseAPIUri}/contract/instance/${uniqid}/paid?instanceToken_${uniqid}=${token}`, checkIfInstanceIsPaid)
    .then(function(response) {
        if (response.ok) {
            return response.json();
        } else {
            console.error(response.json());
        }})
    .then(function(json){
            if(json === true) {
                paidInstanceSession = true;
                deleteHeyflowLocalStorage()
                window.location = window.location.pathname;
            }
    }); 

}

function updateParam(paramName, newValue) {
    var queryParams = new URLSearchParams(window.location.search);
    queryParams.set(`${paramName}`, newValue);
    history.replaceState(null, null, "?"+queryParams.toString());
  }

let paidInstanceSession = false;
let initialEmail = null;
let initialActivity = null;
let initialUniqid = null;
let initialToken= null;
let finishedCall = false;
let comingFromPhoneScreen = false;


window.Client = {
    init: function() {
        // Do something on initialization
        initialEmail = getHeyflowSaveValueFromLocalStorage("input-90a65288");
        initialActivity = getHeyflowSaveValueFromLocalStorage("mc-791787eb");
        initialUniqid = getHeyflowSaveValueFromLocalStorage("input-8d4e52f1");
        initialToken = getHeyflowSaveValueFromLocalStorage("input-aabdb0cc");
        const uniqidParam = getUrlParam("uniqid");
        const tokenParam = getUrlParam("instance_token");
        
        const errorApi = document.querySelector(
            'input[data-variable="errorApi"]'
        );
        errorApi.value = initialEmail;


        if (uniqidParam && tokenParam && tokenParam !== "undefined") {
            checkIfInstanceIsPaidFunction(uniqidParam, tokenParam)
            if ((!localStorage || !localStorage[heyflowLocalStorageKeyName]) && tokenParam && uniqidParam && !paidInstanceSession) {
                var myInit = {
                    ...basicPostHeaders,
                    method: "GET",
                };
                fetch(
                    `${baseAPIUri}/heyflow/${uniqidParam}?instanceToken_${uniqidParam}=${tokenParam}`,
                    myInit
                )
                .then(function(response) {
                    if (response.ok) {
                        return response.json();
                    } else {
                        console.error(response.json());
                    }
                })
                .catch(function(error) {
                    console.error(error);
                    return null
                })
                .then(function(json) {
                    if (json) {
                        localStorage[heyflowLocalStorageKeyName] = json;
                        console.log("reload to restore data from BDD")
                        window.location.reload();
                    }
                })
            }
        } else if ((!uniqidParam || !initialToken) && initialUniqid && initialToken) {
            checkIfInstanceIsPaidFunction(initialUniqid, initialToken)
        } else {
            console.log("instance/token/uniqid can not be checked")        
            /*fetch(
                `${baseAPIUri}/auth/logout`,
                basicPostHeaders
            );*/
        }
    },
    onRender: function(screenId) {
        var input_userEmail = document.querySelector(
            'input[data-variable="email"]'
        );
        var input_uniqid = document.querySelector('input[data-variable="uniqid"]');
        var inputPhoneSelector = document.querySelector('input[data-variable="phone"]');


        switch (screenId) {
            case "screen-9f3a651a": // Screen nom de société
             if (!input_uniqid || !input_uniqid.value) {
                    fetch(
                        `${baseAPIUri}/auth/logout`,
                        basicPostHeaders
                    );
                }
                break;
                

            case "id-e4a2dbc8": // Screen téléphone
                let abtestVersion ;
                var input_Activity = document.querySelector('input[data-variable="activity"]:checked');        
                if (input_Activity) {
                input_Activity = input_Activity.dataset.answer;
                }
                comingFromPhoneScreen = true;

                //create new instance
                            
                    if (
                        !input_uniqid ||
                        !input_uniqid.value ||
                        initialEmail !== input_userEmail.value ||
                        (checkActivity(initialActivity) && !checkActivity(input_Activity)) ||
                        (!checkActivity(initialActivity) && checkActivity(input_Activity))
                    ) {
    
                        let checkoutVersion = getCookie('checkoutVersion');
                        //let checkoutVersion;
                        let slug;

                        /*
                        let emailValue = input_userEmail.value;

                        if (checkEmail(emailValue)) {
                            checkoutVersion = 'webflow-v3';
                            slug = 'packs-v3';
                        } else if (input_Activity && checkActivity(input_Activity)) {
                            checkoutVersion = 'webflow-v7';
                            slug = 'packs-v7';
                        } else {
                            checkoutVersion = 'webflow-v6';
                            slug = 'packs-v6';
                        };*/
    
                        if(checkoutVersion === undefined) {
                            if (Math.random() <0.5) {
                              abtestVersion = "control" ;
                              if (input_Activity && checkActivity(input_Activity)) {
                                  checkoutVersion = 'webflow-v7';
                                  slug = 'packs-v7';
                              } else {
                                  checkoutVersion = 'webflow-v6';
                                  slug = 'packs-v6'; 
                              }
                            } else {
                              abtestVersion = "variant"
                              if (input_Activity && checkActivity(input_Activity)) {
                                  checkoutVersion = 'webflow-v9';
                                  slug = 'packs-v9';
                              } else {
                                  checkoutVersion = 'webflow-v8';
                                  slug = 'packs-v8'; 
                              }
                            }
                            setCookie("checkoutVersion", checkoutVersion, 30);
                      } else {
                          let getVersion = checkoutVersion.split('-').map(value => value.trim()).filter(value => !!value);
                          slug = 'packs-'+getVersion[1];
                      } 
    
                        var myInit = {
                            ...basicPostHeaders,
                            body: JSON.stringify({
                                app_type: "wizardx",
                                instanceDomain: "www.legalplace.fr",
                                draft: 1,
                                email: input_userEmail.value.trim(),
                                metadata: {
                                    checkout: checkoutVersion,
                                    checkoutSlug: slug,
                                },
                                ovc: {
                                    o: {},
                                    v: {}
                                }
                            }),
                        };
                        fetch(
                            `${baseAPIUri}/wizard/instance/creation-sasu/`,
                            myInit
                        )
                        .then(function(response) {
                            return response.json();
                        })
                        .catch(function(reason) {
                            const errorApi = document.querySelector(
                                'input[data-variable="errorApi"]'
                            );
                            errorApi.value = reason;
                            return {};
                        })
                        .then(function(json) {
                            const input_product = document.querySelector(
                                'input[data-variable="product"]'
                            );
                            const input_instanceToken = document.querySelector(
                                'input[data-variable="instanceToken"]'
                            );
                            const input_checkoutVersion = document.querySelector(
                                'input[data-variable="checkoutVersion"]'
                            );
    
                            var uniqid = json.uniqid;
                            input_uniqid.value = uniqid;
                            input_instanceToken.value = getInstanceToken(uniqid);
                            input_product.value = "creation-sasu";
                            initialEmail = input_userEmail.value;
                            input_checkoutVersion.value = checkoutVersion;
                            initialActivity = input_Activity; 
                            finishedCall = true;
                            if (getUrlParam("uniqid")) {
                                updateParam('uniqid', input_uniqid.value)
                            }
    
                            var putSaveABtestDataToLBDD = {
                                method: 'PUT',
                                credentials: 'include',
                                headers: {
                                    'Content-Type': 'application/json; charset=UTF-8'
                                },
                                body: JSON.stringify({
                                    "abTest": {
                                        "checkout v9": abtestVersion
                                    }})
                            }
                            fetch(`${baseAPIUri}/wizard/instance/abTest/${uniqid}`, putSaveABtestDataToLBDD).then()
                            
                        });
    
                    } else {
                        finishedCall = true;
                    }
                
                break;
            case "start": // Que recherchez-vous ?
                if (!input_uniqid || !input_uniqid.value || !comingFromPhoneScreen) {
                    console.log("break")
                    break;
                }

                const inputPhoneValue = inputPhoneSelector.value;
                const instanceUniqid = input_uniqid.value;

                const SalesforceUpdateInit = {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'lp-referrer': window.document.referrer,
                        'lp-origin': window.location.href
                    },
                    body: JSON.stringify({
                        variableTags: [{
                            label: "marketing_TEL",
                            index: 0,
                            value: inputPhoneValue
                        }]
                    }),
                };
                fetch(`${baseAPIUri}/contract-data/tags/${instanceUniqid}`, SalesforceUpdateInit)
                .then(function(response) {
                    return response.json();
                })
                .then(function(json) {
                    const eventInitRequest = {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                            'lp-referrer': window.document.referrer,
                            'lp-origin': window.location.href
                        },
                        body: JSON.stringify({
                            attributes: {
                                'commingFromCheckout': true
                            }
                        })
                    }
                    // SYNC WITH SF
                    fetch(`${baseAPIUri}/event/create/${instanceUniqid}/event`, eventInitRequest).then()
                });
                async function waitForInstanceToBeCreated() {
                    while (!finishedCall) {
                        await delay(1000);
                    }
                }
                waitForInstanceToBeCreated();
                fetch(`${baseAPIUri}/heyflow/${instanceUniqid}`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'lp-referrer': window.document.referrer,
                        'lp-origin': window.location.href
                    },
                    body: JSON.stringify({
                        answers: localStorage[heyflowLocalStorageKeyName],
                    }),
                });
                break;
            default:
                break;
        }
    },
};